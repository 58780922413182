<script>
import api from '@/command/api'
import TemplateHeaderTable from '../../../components/TemplateHeaderTable'
import apiTool from '@/command/apiTool'
import DrawerForm from '../../../components/DrawerForm'
import { postAction, getAction } from '@/command/netTool'
import moment from 'moment'
export default {
  name: 'appVersion',
  data() {
    return {
      ...api.command.getState(),
      storeData: [],
      dataId: null,
      specificationsDataList: [],
    }
  },
  mounted() {
    this.handleGetInit()
  },
  methods: {
    handleGetInit() {
      api.command.getList.call(this, {
        url: '/config/farmVersion/page',
        current: 1,
      })
    },
    getHeader() {
      return [
        {
          name: '名称',
          type: 'input',
          key: 'shopName',
        },
        {
          label: '',
          type: 'text',
          key: '',
        },
      ]
    },
    getColumns() {
      return [
        {
          title: 'ID',
          dataIndex: 'id',
          align: 'left',
          sorter: (a, b) => a.id - b.id,
        },
        {
          dataIndex: 'type',
          title: '名称',
          align: 'left',
          customRender: function (text, records) {
            return '云上大堰智慧导览平台'
          },
          onExport: (text, records) => {
            return '云上大堰智慧导览平台'
          },
        },
        {
          dataIndex: 'type',
          title: '版本',
          align: 'left',
          customRender: function (text, records) {
            return records.type == null ? '' : <div>{records.type == 1 ? '安卓' : 'IOS'}</div>
          },
          onExport: (text, records) => {
            return {
              1: '安卓',
              2: 'IOS',
            }[text]
          },
        },
        {
          dataIndex: 'currVersion',
          title: '新版本号',
          align: 'center',
        },
        {
          dataIndex: 'updateStatus',
          title: '强制更新',
          type: 'badge',
          filter: [
            {
              text: '是',
              value: '1',
            },
            {
              text: '否',
              value: '0',
            },
          ],
          onExport: (records) => {
            return ['否', '是'][records]
          },
          filterMultiple: false,
          render(data) {
            return {
              showDot: true,
              name: data == 1 ? '是' : '否',
              color: data == 1 ? 'green' : 'red',
            }
          },
        },
        {
          dataIndex: 'status',
          title: '状态',
          type: 'badge',
          filters: [
            {
              text: '正常',
              value: '0',
            },
            {
              text: '失效',
              value: '1',
            },
          ],
          onExport: (records) => {
            return ['正常', '失效'][records]
          },
          filterMultiple: false,
          render(data) {
            return {
              showDot: true,
              name: data == 0 ? '正常' : '失效',
              color: data == 0 ? 'green' : 'red',
            }
          },
        },
        {
          dataIndex: 'a9',
          title: '操作',
          type: 'buttonGroup',
          typeData: ({ records }) => {
            return [
              {
                name: '编辑',
                onClick: () => {
                  this.onRowSelect(records)
                },
              },
            ]
          },
        },
      ]
    },
    getButton() {
      return [
        {
          name: '添加',
          type: 'primary',
          icon: 'plus',
          onClick: () => {
            this.onRowSelect()
          },
        },
      ]
    },
    getDrawerForm(source, formData) {
      const form = [
        {
          form: [
            {
              name: '新版本号',
              type: 'input',
              key: 'currVersion',
              cols: 24,
              isShow: true,
            },
            {
              name: '下载链接',
              type: 'input',
              key: 'downloadUrl',
              cols: 24,
              isShow: true,
            },
            {
              name: '强制更新',
              key: 'updateStatus',
              type: 'radioButton',
              cols: 12,
              typeData: [
                {
                  name: '是',
                  value: '1',
                },
                {
                  name: '否',
                  value: '0',
                },
              ],
              isShow: true,
            },
            {
              name: '版本分类',
              key: 'type',
              type: 'radioButton',
              cols: 12,
              typeData: [
                {
                  name: 'IOS',
                  value: '2',
                },
                {
                  name: '安卓',
                  value: '1',
                },
              ],
              isShow: true,
            },
            {
              name: '安卓安装包上传',
              key: 'androidApkUrl',
              type: 'uploadApk',
              isShow: source.type == '1',
            },
            {
              name: '内容',
              type: 'textArea',
              key: 'description',
              isShow: true,
            },
          ].filter((e) => e.isShow),
        },
      ]
      return form
    },
    onRowSelect(records = {}) {
      if (records.id) {
        this.dataId = records.id
        getAction('/api/config/farmVersion/getDetail?id=' + records.id).then((result) => {
          if (result.code == 200) {
            this.handleStatePopup(result.data)
          } else {
            this.$message.error(result.msg)
          }
        })
      } else {
        this.dataId = null
        this.handleStatePopup()
      }

      this.dataId = records.id
    },
    handleStatePopup(source = {}) {
      apiTool.showDrawer({
        title: '更新内容',
        width: '720px',
        view: DrawerForm,
        viewProps: {
          data: (formData) => this.getDrawerForm(source, formData),
          form: source,
        },
        success: (dataSource) => {
          const { data, setHidden } = dataSource

          postAction(`/config/farmVersion/${!this.dataId ? 'add' : 'update'}`, {
            ...data,
            id: this.dataId ? this.dataId : null,
          }).then((result) => {
            if (result.code == 200) {
              setHidden()
              this.$message.success(result.data)
              this.handleGetInit()
            } else {
              this.$message.error(result.msg)
            }
          })
        },
        foot: ({ close, submit }) => {
          return [
            {
              name: '取消',
              onClick: close,
            },
            {
              name: '保存',
              type: 'primary',
              onClick: submit,
            },
          ]
        },
      })
    },
  },
  render() {
    return <TemplateHeaderTable tableColumns={this.getColumns()} button={this.getButton()} records={this.records} />
  },
}
</script>
